import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './shared/styles/globalStyles';
import configureStore from './store';
import theme from './shared/styles/theme';
import { fetchProjects } from './actions';
import { SocialIcons } from './shared/components';

const store = configureStore();
store.dispatch(fetchProjects())

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100vw',
        color: 'rgb(0, 0, 0)',
        fontSize: '4rem',
        fontWeight: 'bold',
        lineHeight: '1.2',
        padding: '0 4rem',
        maxWidth: '900px',
      }}>
        <div>
          Portfolio is undergoing a rebuild - please check back at another time
        </div>
        <SocialIcons />
      </div>
    </ThemeProvider>
  </Provider>
);

export default App;
