export default `
  <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <g>
      <path d="M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256s256-114.844,256-256S397.156,0,256,0z M402.208,263.542l-96,96
        c-2.042,2.042-4.771,3.125-7.542,3.125c-1.375,0-2.76-0.26-4.083-0.813c-3.99-1.646-6.583-5.542-6.583-9.854v-21.333
        c0-2.833,1.125-5.542,3.125-7.542l45.792-45.792H117.333c-5.896,0-10.667-4.771-10.667-10.667v-21.333
        c0-5.896,4.771-10.667,10.667-10.667h219.583l-45.792-45.792c-2-2-3.125-4.708-3.125-7.542V160c0-4.313,2.594-8.208,6.583-9.854
        c4-1.667,8.573-0.74,11.625,2.313l96,96C406.375,252.625,406.375,259.375,402.208,263.542z"/>
    </g>
  </svg>
`;
